import { Button, Card } from "components/ui";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const variant = {
  open: {
    opacity: 1,
    zIndex: 10,
    transform: "translate(-50%,0)",
  },
  hidden: {
    opacity: 0,
    zIndex: -10,
    margin: 0,
    overflow: "hidden",
    transform: "translate(-50%,10%)",
  },
};
export default function AppDownloadNotification() {
  const [open, setIsOpen] = useState(false);
  useEffect(() => {
    if (window.location.pathname.includes("e-swasthya")) return;

    const timeOutId = setTimeout(() => {
      setIsOpen(true);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [setIsOpen]);

  return (
    <>
      {/* <Button onClick={() => setIsOpen((prev) => !prev)}>Download</Button> */}
      <div
        key="download"
        className="fixed bottom-4 transition-all duration-300 ease-in left-1/2 -translate-x-1/2 container"
        style={open ? variant.open : variant.hidden}
      >
        <Card className="prose lg:prose-lg !max-w-max !w-full mx-auto">
          <div className="lg:flex justify-between gap-8">
            <div className="flex flex-col lg:flex-row gap-4 items-center">
              <img
                src="/img/logo/e swasthya icon.png"
                alt=""
                className="w-24 h-24 rounded my-2"
              />{" "}
              <div>
                <h3 className="lg:!my-0 mt-0 lg:text-left text-center">
                  eSwasthya Arunachal
                </h3>
                <p className="lg:!mb-0 text-center">
                  Download app for effortlessly appointment booking
                </p>
              </div>
            </div>
            <div className="flex gap-1 items-center justify-center">
              <Link target="_blank" to="/e-swasthya">
                <Button onClick={() => setIsOpen(false)} variant="solid">
                  Know more
                </Button>
              </Link>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
