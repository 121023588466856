import BaseService from "./BaseService";

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export default ApiService;

export function default_get(url) {
  return BaseService.get(url);
}

export function default_post(url, { arg: data }) {
  return BaseService.post(url, data);
}

export function default_put(url, { arg: data }) {
  return BaseService.put(url, data);
}

export function default_delete(url) {
  return BaseService.delete(url);
}
